<template>
  <div class="entity-header d-flex flex-row justify-space-between">
    <div class="d-flex flex-row align-center align-md-center mr-0 mr-md-5">
      <icon-button :class="media.isMobile ? 'mr-2' : 'mr-6'" :to="backRoute">
        <v-icon>mdi-arrow-left</v-icon>
      </icon-button>

      <div v-if="!loading" class="d-flex flex-column flex-md-row">
        <h1 class="entity-header__title text-body-1 text-md-h4 font-weight-bold">
          {{ title }}
        </h1>
      </div>
    </div>

    <div v-if="(canUpdate || $scopedSlots.actions) && !loading" class="d-flex">
      <slot name="actions">
        <icon-button v-if="media.isMobile" class="mr-2" color="primary" :to="editRoute">
          <v-icon>mdi-pencil</v-icon>
        </icon-button>
        <v-btn v-else color="primary" large class="mr-4" :to="editRoute">
          {{ $t('button.edit') }}
          <v-icon right>mdi-pencil</v-icon>
        </v-btn>

        <icon-button
          v-if="media.isMobile"
          color="secondary"
          class="primary--text"
          :disabled="roleInUse"
          @click="deleteRole"
        >
          <v-icon>mdi-delete</v-icon>
        </icon-button>
        <v-btn v-else color="secondary" class="primary--text" large :disabled="roleInUse" @click="deleteRole">
          {{ $t('button.delete') }}
          <v-icon right>mdi-delete</v-icon>
        </v-btn>
      </slot>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'EntityHeader',

  components: { IconButton },

  inject: ['media'],

  props: {
    title: { type: String, default: '' },
    backRoute: { type: Object, default: () => ({}) },
    editRoute: { type: Object, default: () => ({}) },
    canUpdate: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    roleInUse: { type: Boolean, default: false },
  },

  methods: {
    deleteRole() {
      this.$emit('delete-role');
    },
  },
};
</script>

<style lang="scss">
.entity-header {
  &__secondary {
    opacity: 0.5;
  }

  &__title {
    word-break: break-all;
  }
}
</style>
