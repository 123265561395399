import { getCredentialsObject } from '@/schemas/createModelData';

// eslint-disable-next-line import/prefer-default-export
export const getCredentialsFromSections = data => {
  const formatSection = getCredentialsObject();

  data.accessLevels.forEach(section => {
    if (data.accessLevels.find(access => access.name === section.name)) {
      if (!formatSection[section.name]) {
        formatSection[section.name] = [];
      }
      formatSection[section.name].push(section.type);
    }
  });

  return formatSection;
};
