import * as schemaItemTypes from './schemaItemTypes';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.CREDENTIALS_VARIANT,
    label: 'employee.credentials',
    prop: 'accessLevels',
  },
];

export { schema as default };
