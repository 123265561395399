<template>
  <div>
    <entity-header
      class="mb-10"
      :title="roleName"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdate"
      :role-in-use="role.roleInUse"
      :loading="isLoading"
      @delete-role="deleteRole"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schema" :data="role" />
  </div>
</template>

<script>
// Models
import { schema } from '@/schemas/role.view.schema';
import notificationService from '@/services/notification';

// Utils
import { getCredentialsFromSections } from '@/utils/roles';

// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

// Constants
import { ROLES, ROLES_EDIT } from '@/constants/routes';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/Roles/EntityHeader.vue';
import { ROLE, DELETE_ROLE } from '@/constants/analyticsActions';
import { EMPLOYEES as EMPLOYEES_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

export default {
  name: 'EmployeeDetailed',

  components: { EntityHeader, ViewBuilder },

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      role: {},
    };
  },

  computed: {
    canUpdate() {
      return this.$can(UPDATE, EMPLOYEES_SUBJECT);
    },

    backRoute() {
      return { name: ROLES, query: { page: this.$route.params.prevPage || 1 } };
    },

    roleName() {
      return this.role?.name || '';
    },

    editRoute() {
      return {
        name: ROLES_EDIT,
        params: { id: this.id, prevPage: this.$route.params.prevPage || 1, fromDetailed: true },
      };
    },
  },

  async mounted() {
    analyticsService.track(ROLE);
    this.isLoading = true;
    try {
      await this.initDetail();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    getCredentialsFromSections,
    async deleteRole() {
      try {
        this.isLoading = true;
        await usersService.deleteRole(this.id);
        analyticsService.track(DELETE_ROLE);

        notificationService.success(this.$t('role.delited'), 2000);

        this.$router.push({ name: ROLES });
      } catch (error) {
        const keys = Object.keys(error?.response?.data);

        if (keys.length) {
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const errorText = this.getErrorText(error.response.data, key);
            this.$options.notificationItem = notificationService.error(errorText);
          }
        }
      } finally {
        this.isLoading = false;
      }
    },

    async initDetail() {
      const role = await usersService.getRoleById(this.id);
      const newRole = role;
      newRole.accessLevels = this.getCredentialsFromSections(newRole);
      this.role = newRole;
    },

    getErrorText(data, key) {
      return Array.isArray(data[key]) ? data[key][0] : data[key];
    },
  },

  schema,
  notificationItem: null,
};
</script>
